const SearchReducer = (state = false, action) => {
  console.log(action);
  switch(action.type){
    case "TOGGLE_SEARCH":
      state = !state
      return state;
    default:
      return state;
  }
}

export default SearchReducer;