import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from './route_components/PrivateRoute';
import io from "socket.io-client";
import SocketContext from '../src/apis/Socket';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading.....</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const socket = io.connect('https://api.xpresscure.com/support');
// const socket = io.connect('https://dev.xpresscure.com/support');

socket.on('connect', () => {
	// console.log("CONNECTED App.js", socket);
})

// const socketConsult = io.connect('https://dev.xpresscure.com/consultation1');

const socketConsult = io.connect('https://api.xpresscure.com/consultation1');
socketConsult.on('connect', () => {
	// console.log("CONNECTED App.js socketConsult", socketConsult);
})
// const socket = useContext(SocketContext);

class App extends Component {

	render() {
		return (
			<HashRouter>
				<React.Suspense
					fallback={loading()}
				>
					<SocketContext.Provider value={{ socket: socket, socketConsult: socketConsult }}>
						<Switch>
							<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
							{/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
							<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
							<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
							<PrivateRoute path="/" name="Home" component={DefaultLayout} />
							{/* <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
						</Switch>
					</SocketContext.Provider>

				</React.Suspense>
			</HashRouter>
		);
	}
}

export default App;
