import { combineReducers } from 'redux';
import formDataReducer from './formDataReducer';

import NotificationReducer from './notification_reducer';
import SearchReducer from './search_reducer';

const rootReducer = combineReducers({
  NotificationReducer,
  SearchReducer,
  formDataReducer
})

export default rootReducer;