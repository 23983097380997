import { LogoutEndpoint } from '../apis/Network'
import { POST } from '../apis/constants'
import { HttpCallPost } from '../apis/usehttps'
import * as io from 'socket.io-client';


export const isLogin = () => {
    var data = JSON.parse(localStorage.getItem('userInfo'));

    if(data === null)
        return false;
    if(data.token){
        return true;
    }
    return false;
}

export const hasAccess = (path) => {
    if(path.toLowerCase() === '/profile' || path.toLowerCase() === '/settings' || path.toLowerCase() === '/dashboard')
        return true;
    console.log("Length = ", path.split('/').length)
    var data = JSON.parse(localStorage.getItem('userAccess'));
    var info = JSON.parse(localStorage.getItem('userInfo'))
    // console.log( info, 'login data')
    if(info === null)
        return false;
    if(info.role === 'admin') // admin has complete access
        return true;
    if(data === null)
        return false;
    var index = data.findIndex(item => (item.path.toLowerCase() === `/${path.split('/')[1].toLowerCase()}`) || (item.path.toLowerCase() === path.toLowerCase()))
    // console.log("Access Called", index, path)
    if(index > -1){
        return data[index].view;
    }
    else{
        return false;
    }
}

export const LoginUser = (data) => {
    //API
    localStorage.setItem('userInfo', JSON.stringify({token: data.accessToken, refresh: data.refreshToken, role: data.details.role, details: data.details}));
    // localStorage.setItem('userAccess', JSON.stringify(obj))
}

export const SetPermission = (obj) => {
    localStorage.setItem('userAccess', JSON.stringify(obj))
}

export const LogoutUser = () => {
    const p = JSON.parse(localStorage.getItem('userInfo'));
    HttpCallPost(`${LogoutEndpoint}`, POST, {
        refresh_token: p.refresh
    }).then(res => {
        
        const socket = io.connect('https://api.xpresscure.com/support');
        console.log("socket value in islogin file", socket)

        socket.on('disconnect', () => {
            console.log('user disconnected in logout file');
          });
        console.log("Logged you out", res.data)
    })
    localStorage.removeItem('userInfo')
    localStorage.removeItem('userAccess')
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations()
            .then(function(registrations) {
                for(let registration of registrations) {
                    console.log("Unregister log", registration.active.scriptURL)
                   if(registration.active.scriptURL.split('/').pop() == 'firebase-messaging-sw.js'){ registration.unregister(); }
                }
            });
    }
}