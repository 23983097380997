const NotificationReducer = (state = [], action) => {
  switch(action.type){
    case "ADD_NOTIFICATION":
      state = [{...action.payload, read: false}, ...state]
      return state;
    case "REMOVE_NOTIFICATION":
      state = state.filter(item => item._id !== action.payload)
      return state;
    case "SEEN_NOTIFICATION":
      state = state.map(item => item._id === action.payload ? { ...item, read: true } : item)
      return state;
    default:
      return state;
  }
}

export default NotificationReducer;