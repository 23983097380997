const formDataReducer = (state = { AudioCall: "", ActivePage: 1 }, action) => {
  console.log(action);
  switch (action.type) {
    case "AudioCall":
      state = { ...state, AudioCall: action.payload }
      return state;
    case "ActivePage":
      state = { ...state, ActivePage: action.payload }
      return state;
    default:
      return state;
  }
}

export default formDataReducer;