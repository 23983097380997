import React, { Component } from "react";
import { Route, Redirect, useHistory } from 'react-router-dom'
import {isLogin, hasAccess} from './isLogin'

const PrivateRoute = ({component: Component, ...rest }) => {
    const history = useHistory();
    console.log("Router", history);
    return(
    <Route {...rest} render={props => (
        isLogin() ? (
            (hasAccess(history.location.pathname)) ? (<Component {...props}/>) : (<Redirect to="/dashboard"/>)
        ) : (
            <Redirect to="/login"/>
        )
    )}/>)
}

export default PrivateRoute;