import io from "socket.io-client";
import React from 'react';
// import SupportChat from '../views/Support/SupportChat';
// import Support from '../views/Support/Support';

// export const socket = io.connect('https://dev.xpresscure.com/support');
export const SocketContext = React.createContext();

// function GlobalSocket(){

//     const [socketConnected, setSocketConnected] = useState(false);
    // const [socket, setSocket] = useState(null);

    // useEffect(() => {
    //     if(!socketConnected)
    //       setSocket(io('https://dev.xpresscure.com/support'));
    //       return;
    //   }, []);
    
    //   useEffect(() => {
    //     if (!socket) return;
    //       socket.on('connect', () => {
    //         // setSocketConnected(socket.connected);
    //         console.log("CONNECTED Socket.js", socket);
    //       });
    //       socket.on('disconnect', () => {
    //         // setSocketConnected(socket.connected);
    //         console.log("DISCONNECTED Socket.js");
    //       });
       
    //     }, []);

//     return(
//         <div> 
//             <SocketContext.Provider value={socket}>
//                 <Support />
//                 <SupportChat />
//             </SocketContext.Provider>
//         </div>
//     );
// }

export default SocketContext;

