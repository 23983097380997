import { BaseUrl } from "../apis/Network";
// import Swal from 'sweetalert2/dist/sweetalert2.js'
import Swal from "sweetalert2";

const axios = require("axios");

// For Post Api Calls And Put
export const HttpCallPost = async (method, type, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    // console.log("url", url, body,type)
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log(userInfo);
    axios({
      method: type,
      url: url,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        "authorization": userInfo ? userInfo.token : ""
      },
      data: body,
    })
      .then((response) => {
        if (response.status === 200) {
          var type = typeof response.data.msg;
          if(type === 'string'){
            // console.log("fired", response.data.msg)
            // Swal.fire({
            //   position: "center",
            //   type: "error",
            //   title: response.data.msg,
            // });
          }
          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
// for use content type multipart
export const HttpCallSearch = async (method, type, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    // console.log("url", url, body,type)
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));    
    axios({
      method: type,
      url: url,
      headers: {
        "content-type": "application/json",
        Accept: "application/json",
        "authorization": userInfo ? userInfo.token : ""
      },
      data: body,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.dataList) {
            if (response.data.dataList.length === 0) {
              Swal.fire({
                position: "center",
                type: "error",
                title: "Not Found",
              });
            }
          }
          if (response.data.list) {
            if (response.data.list.length === 0) {
              Swal.fire({
                position: "center",
                type: "error",
                title: "Not Found",
              });
            }
          }
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

//
export const HttpCallFormData = async (method, type, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    // console.log("url", url, body,type)
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    axios({
      method: type,
      url: url,
      headers: {
        "content-type": "multipart/form-data",
        // Accept: 'multipart/form-data',
        "authorization": userInfo ? userInfo.token : ""
      },
      data: body,
    })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            type: "error",
            title: response.data.msg,
          });
          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

//For Get Api Calls
export const HttpCallGet = async (method, type, body) => {
  return new Promise(async function (resolve, reject) {
    const url = BaseUrl + method;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));    
    axios
      .get(url, {
        method: type,
        headers: {
          "Content-Type": "application/json",
          "authorization": userInfo ? userInfo.token : ""
        },
        data:body,
      })
      .then((response) => {
        if (response.status === 304) {
          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};


//
export const HttpCallDelete = async (method, type,body, token) => {
  // console.log("method, type, token",   token)
  return new Promise(async function (resolve, reject) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));    

    const url = BaseUrl + method;
    axios
      .delete(url, {
        headers: {
          "Content-Type": "application/json",
          "authorization": userInfo ? userInfo.token : "",
        },
        data: body
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            type: "error",
            title: response.data.msg,
          });
          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

//For Api Error Handling Globaly
export const handleError = (errResponse) => {
  if (errResponse.response.status === 403) {
    localStorage.clear();
    return Swal.fire({
      position: "center",
      type: "error",
      title: "Invalid User",
    }).then((ok) => {
      window.location.assign("/");
    });
  } else if (errResponse.response.status === 404) {
    Swal.fire({
      position: "center",
      type: "error",
      title: errResponse.response.data.msg,
    });
  } else if (errResponse.response.status === 500) {
    Swal.fire({
      position: "center",
      type: "error",
      title: errResponse.response.data,
    });
  } else if (errResponse.response.status === 400) {
    Swal.fire({
      position: "center",
      type: "error",
      title: errResponse.response.data,
    });
  } else if (errResponse.response.status === 401) {
    Swal.fire({
      position: "center",
      type: "error",
      title: "Unauthorized",
    });
  }
};
export const HttpCallImgPost = async (method, type, token, body) => {
  return new Promise(async function (resolve, reject) {
    console.log("method, type, token,body", method, type, token, body);
    const url = BaseUrl + method;
    console.log("url", url, body, type);
    axios({
      method: type,
      url: url,
      headers: {
        "content-type": "multipart/form-data",
        // "X-API-KEY": token,
      },
      data: body,
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          type: "error",
          title: "image Successfully uploaded",
        });
        if (response.status === 200) {
          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
